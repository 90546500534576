import { Injectable } from '@angular/core';

import { AbstractErosApiConfig } from '@eros-api/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class ErosCellarApiConfigService extends AbstractErosApiConfig {
  override getApiUrl(): string {
    return environment.apiUrl;
  }
}
