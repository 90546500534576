import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  UrlTree,
  Router
} from '@angular/router';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DevelopmentGuard {
  constructor(private router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.checkMainPageNavigation();
  }

  canActivateChild(_childRoute: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.checkMainPageNavigation();
  }

  canLoad(_route: Route): boolean | UrlTree {
    return this.checkMainPageNavigation();
  }

  checkMainPageNavigation(): boolean {
    if (environment.production) {
      this.router.navigate(['app', 'tickets']);

      return false;
    }

    return true;
  }
}
