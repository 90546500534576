import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/libs/auth/guards';
import { RoleGuard } from '@app/libs/auth/guards/role.guard';
import { DevelopmentGuard } from '@app/libs/auth/guards/development.guard';

const routes: Routes = [
  {
    path: 'sign-in',
    loadChildren: () =>
      import('@app/libs/auth/shell').then((m) => m.AuthShellRoutingModule)
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    loadChildren: () => import('@app/core/shell').then((m) => m.CoreShellModule)
  },
  {
    path: 'ui-kit',
    loadChildren: () =>
      import('@app/ui-kit/ui-kit.module').then((m) => m.UiKitModule),
    canActivate: [DevelopmentGuard]
  },
  { path: '', redirectTo: '/app/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
