import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '@app/libs/auth/data-access';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.hasAccessToken()) {
      this.authService.removeAccessToken();
      this.authService.removePermissions();
      this.authService.redirectUrl = state.url;
      return this.router.createUrlTree(['/sign-in']);
    }

    return true;
  }

  canLoad(
    _route: Route,
    _segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAccessTokenExpired()) {
      this.authService.redirectUrl = this.router.url;
      return this.router.createUrlTree(['/sign-in']);
    }

    return true;
  }
}
