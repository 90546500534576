import { FactoryProvider } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { JWT_OPTIONS, JwtConfig } from '@auth0/angular-jwt';

import { environment } from '@env/environment';

export function jwtOptionsFactory(document: Document): JwtConfig {
  const localStorage = document.defaultView!.localStorage;
  // you can get request localStorage for access-token just one time
  // const accessToken = localStorage ? localStorage.getItem('access-token') : null;

  return {
    allowedDomains: [
      new URL(environment.apiUrl).host,
      new URL(environment.contentServerUrl).host
    ],
    disallowedRoutes: [`${environment.apiUrl}/account/token`],
    tokenGetter: () => {
      // request localStorage for access-token on every request
      return localStorage ? localStorage.getItem('access-token') : null;
    }
  };
}

export const JWT_OPTIONS_PROVIDER: FactoryProvider = {
  provide: JWT_OPTIONS,
  useFactory: jwtOptionsFactory,
  deps: [DOCUMENT]
};
