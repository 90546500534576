import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions
} from '@angular/material/form-field';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl
} from '@angular/material/paginator';

import { JwtModule } from '@auth0/angular-jwt';

import { ErosApiModule } from '@eros-api/core';
import { ErosCellarApiConfigService } from '@app/core/services';
import { JWT_OPTIONS_PROVIDER } from '@app/core/providers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RootStoreModule } from './store';
import { TimeagoModule } from '@app/libs/ticket/features/tickets/pipes/timeago';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { StoreModule } from '@ngrx/store';
import {
  notificationsHistoryFeature,
  notificationsHistoryPageEffects
} from '@app/libs/account-notification/features/notifications-history/state';
import { EffectsModule } from '@ngrx/effects';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { Toasty } from '@app/libs/shared/typings/toasty/toasty.enum';
import { ColorPickerModule } from 'ngx-color-picker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatDialogModule } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill';

@Injectable()
export class AppPaginatorIntl extends MatPaginatorIntl {
  override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ErosApiModule.forRoot(ErosCellarApiConfigService),
    JwtModule.forRoot({ jwtOptionsProvider: JWT_OPTIONS_PROVIDER }),
    AppRoutingModule,
    RootStoreModule,
    TimeagoModule.forChild(),
    MatNativeDateModule,
    NgxMatMomentModule,
    NgxMatNativeDateModule,
    StoreModule.forFeature(notificationsHistoryFeature),
    EffectsModule.forFeature(notificationsHistoryPageEffects),
    QuillModule.forRoot(),
    ColorPickerModule,
    FullCalendarModule,
    MatDialogModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: 'outline',
        floatLabel: 'auto',
        hideRequiredMarker: false
      }
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
        hidePageSize: false,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50],
        showFirstLastButtons: false
      } as MatPaginatorDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        panelClass: Toasty.Info,
        duration: 3000
      } as MatSnackBarConfig
    },
    {
      provide: MatPaginatorIntl,
      useClass: AppPaginatorIntl
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
