import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  UrlTree
} from '@angular/router';
import { AuthService } from '@app/libs/auth/data-access';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.hasExpected(route.data);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.hasExpected(childRoute.data);
  }

  canLoad(route: Route): boolean | UrlTree {
    return this.hasExpected(route.data);
  }

  private hasExpected(routeData): boolean | UrlTree {
    const expectedRoles: undefined | string[] = routeData.roles;
    const expectedPerm: undefined | string = routeData.permission;

    let hasExpectedRole = true;
    let hasExpectedPerm = true;

    if (expectedRoles) {
      hasExpectedRole = expectedRoles.includes(
        this.authService.getDecodedAccessToken().role
      );
    }

    if (expectedPerm) {
      hasExpectedPerm = this.authService.hasPermission(expectedPerm);
    }

    if (hasExpectedRole && hasExpectedPerm) {
      return true;
    }

    return this.router.createUrlTree(['/app/tickets']);
  }
}
